.colorBlack100{
    color: $colorPrimaryBlack;
}

.colorBlack70{
    color: $colorSystemBlack70;
}

.colorBlack60{
    color: $colorSystemBlack60;
}

.colorBlack50{
    color: $colorSystemBlack50;
}

.colorBlack10{
    color: $colorSystemBlack10;
}

.colorLight{
    color: $colorSystemLight;
}

.colorWhite{
    color: $colorSystemWhite;
}

.bg-colorBlack100{
    background-color: $colorPrimaryBlack;
}

.bg-colorBlack70{
    background-color: $colorSystemBlack70;
}

.bg-colorBlack60{
    background-color: $colorSystemBlack60;
}

.bg-colorBlack50{
    background-color: $colorSystemBlack50;
}

.bg-colorBlack10{
    background-color: $colorSystemBlack10;
}

.bg-light{
    background-color: $colorSystemLight !important;
}

.bg-white{
    background-color: $colorSystemWhite;
}

.bgOverlay{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.boxShadow{
    background: $colorSystemWhite;
    box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.on-desktop{
    display: block;

    @include media-breakpoint-down(md){
        display: none;
    }
}

.logo{
    display: block;
    width: 100%;
    height: auto;
}

.icon{
    display: block;
    width: 32px;
    height: auto;
}

.delay-animation-mcl {
    transition: all 0.8s ease;
    overflow: hidden;
}

/* scrolldownicon */
.scrolldownicon {
    position: absolute;
    bottom: 125px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
}

.scrolldownicon .mouse {
    height: 28px;
    width: 18px;
    border-radius: 8px;
    transform: none;
    border: 2px solid white;
}

.scrolldownicon .mouse .wheel {
    height: 5px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: white;
    position: relative;
    animation: mouse-cmm 1.2s ease infinite;
}

.mwheel {
    display: block;
    width: 10px;
    height: 10px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    margin: 2px 4px;
    animation: arrow-cmm 1.2s ease infinite;
}

.mwheel1 {
    animation-delay: 1100ms;
}

.mwheel2 {
    animation-delay: 1200ms;
}

.mwheel3 {
    animation-delay: 1300ms;
}

@keyframes mouse-cmm {
    0% {
        transform: translateY(-1px);
        opacity: .6;
    }
    10% {
        transform: translateY(1px);
        opacity: 1;
    }
    20% {
        transform: translateY(2px);
        opacity: .8;
    }
    30% {
        transform: translateY(3px);
        opacity: .7;
    }
    40% {
        transform: translateY(4px);
        opacity: .6;
    }
    50% {
        transform: translateY(5px);
        opacity: .4;
    }
    60% {
        transform: translateY(6px);
        opacity: .2;
    }
    80% {
        transform: translateY(7.5px);
        opacity: .1;
    }
    100% {
        transform: translateY(10px);
        opacity: 0;
    }
}

@keyframes arrow-cmm {
    0% {
        opacity: .5;
    }
    10% {
        opacity: .4;
    }
    25% {
        opacity: .7;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    75% {
        opacity: .4;
    }
    100% {
        opacity: 0;
    }
}

.line-animation{
    display: block;
    position: absolute;
    width: 0px;
    height: 2px;
    bottom: 105px;
    left: 45px;
    background-color: #fff;
    /*visibility: hidden;*/
    -webkit-transition: all .4s linear;
    -moz-transition: all .4s linear;
    -o-transition: all .4s linear;
    transition: all .4s linear;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
}

.button{
    display: block;
    font-family: $fontPrimary;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $colorSystemWhite;
    padding: 16px 64px;
    border: 0;
    border-radius: 0;
    position: relative;

    &.btn-primary{
        background-color: $colorPrimaryBlack;

        &:active{
            background-color: $colorSystemBlack70 !important;
            border: 0 !important;
        }

        &:focus{
            box-shadow: none !important;
        }
    }

    &.btn-line{
        color: $colorPrimaryBlack;
        background-color: $colorSystemWhite;
        border: 1px solid $colorPrimaryBlack;

        &:hover{
            background-color: $colorSystemBlack10;
        }

        &:active{
            background-color: $colorSystemWhite !important;
        }

        &:focus{
            border: 1px solid $colorPrimaryBlack !important;
            box-shadow: none !important;
        }
    }

    &::after{
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $colorSystemBlack50;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .4s ease;
    }

    &:hover{
        &::after{
            top: 10px;
            left: 10px;
        }
    }

    @include media-breakpoint-down(md){
        width: 100%;
    }
}

.btn-totop{
    display: none;
    color: $colorSystemWhite;
    width: 60px;
    height: 60px;
    padding: 0;
    text-align: center;
    background-color: $colorSystemBlack70;
    border: 0;
    border-radius: 0;
    position: fixed;
    right: 16px;
    bottom: 96px;
    z-index: 100;

    &:hover{
        svg{
            transform: scale(1.2);
        }
    }

    svg{
        width: 16px;
        height: auto;
    }

    @include media-breakpoint-down(md){
        width: 48px;
        height: 48px;
    }
}

#hubspot-messages-iframe-container{
    z-index: 1000 !important;
}
