.mortgage-calculator{
    .single{
        &-entry{
            &-post{
                padding-bottom: 160px;
            }

            &-left{
                margin-left: 90px;
            }

            &-right{
                margin-right: 90px;
            }
        }
    }

    .mortgage-calculation{
        padding: 32px;

        .form{
            &-group{
                margin-bottom: 32px;

                &.label{
                    margin-bottom: 0;
                }
            }

            &-row{
                margin-bottom: 40px;

                &:last-child{
                    margin-bottom: 0;
                }

                div{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0;

                    &:first-child{
                        h3{
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }

    .estimated-summary{
        padding: 32px 64px;
        box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.1);

        .form-header{
            text-align: center;
        }

        &-top{
            margin-bottom: 80px;
            text-align: center;
        }

        &-bottom{
            h3{
                margin-bottom: 24px;
            }

            ul{
                margin-bottom: 32px;

                li{
                    padding: 19px 0;
                    border-bottom: 1px solid $colorSystemBlack10;
                }
            }
        }
    }

    @include media-breakpoint-down(md){
        .single{
            &-entry{
                &-post{
                    padding-bottom: 0;

                    .container-fluid{
                        padding-left: 0;
                        padding-right: 0;
                    }
                }

                &-header{
                    padding-left: 20px;
                    padding-right: 20px;
                }

                &-left{
                    margin-left: 32px;
                    margin-right: 32px;
                }

                &-right{
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }

        .mortgage-calculation{
            padding: 32px 0;
            background-color: $colorSystemWhite !important;

            .form{
                &-group{
                    margin-bottom: 24px;
                    text-align: left;
                }

                &-row{
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }

        .estimated-summary{
            padding: 32px;
            box-shadow: none;
            background-color: $colorSystemLight !important;

            .form-header{
                margin-bottom: 16px;
            }

            &-top{
                margin-bottom: 24px;

                h1{
                    font-size: 48px;
                    line-height: 96px;
                }
            }

            &-bottom{
                h3{
                    margin-bottom: 8px;
                }

                ul{
                    margin-bottom: 32px;

                    li{
                        padding: 12px 0;
                    }
                }
            }
        }
    }
}
