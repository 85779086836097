.home-hero{
    padding-top: 90px;

    .container-fluid{
        padding-left: 0;
        padding-right: 0;
    }

    .black-space{
        max-width: 90px;
    }

    &-wrap{
        position: relative;
        width: 100%;
        height: 814px;
        overflow: hidden;

        &::before{
            display: block;
            content: "";
            width: 100%;
            padding-top: (407 / 720) * 100%;
        }

        .home-hero-bg{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            img{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    &-copy{
        position: absolute;
        top: 0;
        left: 90px;
        width: 50vw;
        height: 80vh;

        .greetings{
            margin-top: auto;
            opacity: .7;
        }

        .title{
            margin-bottom: auto;
        }
    }

    &-period{
        position: absolute;
        top: 0;
        right: 0;
        width: 77px;
        height: auto;

        &-weather{
            padding: 32px 16px;

            img{
                margin-bottom: 32px;
            }
        }

        &-date{
            padding: 32px 16px;

            h2{
                margin-top: 24px;
                margin-bottom: 24px;
            }

            p{
                text-transform: uppercase;
            }
        }
    }

    &-slider{
        height: 180px;
        margin-left: 90px;
        margin-right: 90px;
        position: relative;
        transform: translateY(-90px);
        filter: drop-shadow(0px 24px 44px rgba(0,0,0,0.5));

        .item{
            width: 100%;
            height: 100%;
            position: relative;

            &:hover{
                text-decoration: none;
            }

            &-bg{
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                &::after{
                    display: block;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.25);
                }
            }

            img{
                height: 100%;
                object-fit: cover;
                object-position: center;
                position: relative;
            }

            h4{
                position: absolute;
                margin-left: 22px;
                margin-bottom: 22px;
            }
        }

        .owl-carousel{

            .owl-item{
                height: 180px;
                overflow: hidden;
            }

            .owl-nav{
                // display: block;

                button{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45px;
                    height: 45px;
                    background-color: #FFFFFF !important;
                    box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.25) !important;
                }

                .owl-prev{
                    position: absolute;
                    left: -23px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .owl-next{
                    position: absolute;
                    right: -23px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }


            &.no-carousel{
                display: flex;

                .item{
                    height: 180px;
                    overflow: hidden;

                    img{
                        width: 100%;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md){
        padding-top: 70px;

        .black-space{
            display: none;
        }

        &-wrap{
            height: 486px;

            &::before{
                padding-top: (162 / 125) * 100%;
            }
        }

        &-copy{
            width: 100%;
            height: auto;
            top: unset;
            left: 0;
            bottom: 89px;
            align-items: center !important;

            .greetings{
                margin-top: 0;
            }

            .title{
                margin-bottom: 0;
                text-align: center;
                padding: 0 24px;
            }
        }

        &-period{
            width: 46px;

            &-weather{
                padding: 16px 8px;

                img{
                    width: 24px;
                    height: auto;
                    margin-bottom: 0;
                }
            }

            &-date{
                padding: 16px 8px;

                h2{
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }

        &-slider{
            width: 100%;
            height: auto;
            margin: 0;
            transform: translateY(0);
            filter: none;

            h3{
                margin-top: 8px;
                margin-bottom: 12px;
            }

            .owl-carousel{
                filter: drop-shadow(0px 24px 44px rgba(0, 0, 0, 0.25));

                .owl-item{
                    height: 130px;
                    margin-top: 15px;

                    &.active{
                        height: 160px;
                        margin-top: 0;
                    }
                }

                .owl-nav{
                    display: none;
                }

                .owl-dots{
                    text-align: center;
                    padding: 24px 0;
                }

                .owl-dot{
                    margin-right: 8px;

                    &:last-child{
                        margin-right: 0;
                    }

                    &.active{
                        span{
                            width: 8px;
                            height: 8px;
                            background-color: #FFFFFF;
                            border-radius: 4px;
                        }
                    }

                    span{
                        display: block;
                        width: 6px;
                        height: 6px;
                        background-color: #8C8C8C;
                        border-radius: 3px;
                    }
                }
            }
        }
    }
}

.home-submenu{
    .container-fluid{
        padding-left: 0;
        padding-right: 0;
    }

    .row{
        margin-left: 90px;
        margin-right: 90px;
        padding-bottom: 90px;
    }

    .submenu-item{
        position: relative;
        display: block;
        width: 100%;
        background-color: $colorSystemWhite;
        overflow: hidden;

        &:before{
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            padding-top: (9 / 7) * 100%;
        }

        &-bg{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
            transform: scale(1);
            transition: all 0.8s ease;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            .bg-gradiant{
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                opacity: 0.75;
            }
        }

        &:hover{
            box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.25);

            .submenu-item-bg{
                transform: scale(.9);
            }

            .hover-zoom{
                transform: scale(1.3);
            }

            .delay-animation-mcl {
                transition: all 0.8s ease;
                overflow: hidden;
            }

            .submenu-item-title h2{
                margin-left: 105px;
            }

            .line-animation{
                visibility: visible;
                width: 48px;
                -webkit-transform: scaleX(1);
                -moz-transform: scaleX(1);
                -o-transform: scaleX(1);
                transform: scaleX(1);
            }
        }

        &-title{
            display: flex;
            align-items: flex-end;
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;

            h2{
                width: 260px;
                margin-left: 45px;
                margin-bottom: 45px;
                transition: all 0.8s ease;
            }
        }
    }

    @include media-breakpoint-down(md){
        .row{
            margin: 0 20px;
            padding: 48px 0;
        }

        .submenu-item{
            height: 200px;
            margin-bottom: 20px;

            &::before{
                padding-top: (40 / 67) * 100%;
            }

            &:hover{
                .submenu-item-title h2{
                    margin-left: 80px;
                }
            }

            &-title{
                h2{
                    width: auto;
                    margin-left: 24px;
                    margin-bottom: 24px;
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .scrolldownicon{
        bottom: 10px;
    }

    .line-animation{
        bottom: 45px;
        left: 24px;
    }
}
