.masterpieces{
    .scrolldownicon{
        bottom: 144px;
        left: 95%;
        transform: translateX(-95%);
    }

    &-filter{
        margin: 135px 90px 45px;
        display: block;
        width: 100%;

        .navbar{
            padding: 32px 0;

            &-brand{
                margin-left: 64px;
                margin-right: 0;
                padding: 0;
            }

            &-nav{
                margin: 0;
            }
        }

        .nav{
            &-item{
                margin-right: 24px;
            }
        }

        .form{
            width: auto;
            margin-right: 90px;

            .customselect{
                width: 242px;
                margin-right: 24px;
                position: relative;

                &:last-child{
                    margin-right: 0;
                }

                .dropdown{
                    width: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    &-list{
        padding: 0 75px 90px;
    }

    &-item{
        position: relative;
        display: block;
        width: 100%;
        background-color: $colorSystemWhite;
        overflow: hidden;

        &:before{
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            padding-top: (9 / 7) * 100%;
        }

        &-bg{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
            transform: scale(1);
            transition: all 0.8s ease;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            .bg-gradiant{
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.25);
            }
        }

        &-title{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;

            .availibility{
                padding: 8px 16px;
                margin-bottom: 4px;
            }

            h3{
                margin-left: 45px;
                margin-bottom: 4px;
            }

            p{
                margin-left: 45px;
            }

            .btc{
                margin-bottom: 64px;
                transition: all .8s ease;

                svg{
                    margin-right: 8px;
                }
            }
        }

        &-btn{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 64px;
            bottom: 0;
            left: 0;

            .rtl{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 74%;
                height: 64px;
                padding-left: 32px;

                &::before{
                    display: block;
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 100%;
                    background-color: $colorSystemBlack10;
                    transition: width .8s ease;
                    right: 64px;
                }

                span{
                    opacity: 0;
                    z-index: 10;
                    transition: opacity .1s ease-in;
                    transition-delay: .4s;
                }
            }

            .arrow{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 64px;
                height: 64px;
            }
        }

        &:hover{
            box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.25);

            .masterpieces-item-bg{
                transform: scale(.9);
            }

            .hover-zoom{
                transform: scale(1.3);
            }

            .delay-animation-mcl {
                transition: all 0.8s ease;
                overflow: hidden;
            }

            .btc {
                margin-bottom: 120px !important;
            }

            .rtl{
                &::before{
                    width: 74%;
                    background-color: $colorPrimaryBlack;
                }

                span{
                    opacity: 1;
                }
            }

            .arrow{
                background-color: $colorPrimaryBlack;

                svg{
                    path{
                        fill: $colorSystemWhite;
                    }
                }
            }
        }
    }

    .empty{
        img{
            margin-top: 30vh;
            margin-bottom: 16px;
        }

        .no-data{
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30vh;
        }
    }

    .pagination{
        padding-bottom: 90px;

        &-item{
            width: 56px;
            height: 56px;

            &.active{
                background-color: $colorSystemBlack10;
            }

            a{
                &:hover{
                    color: initial;
                    text-decoration: none;
                }
            }
        }
    }

    @include media-breakpoint-down(md){
        .scrolldownicon{
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
        }

        &-content{
            .container-fluid{
                padding-left: 20px;
                padding-right: 20px;
            }

            .row{
                &:first-child{
                    margin-left: -20px;
                    margin-right: -20px;
                }
            }
        }

        &-filter{
            margin: 0 0 45px;

            .navbar{
                padding: 24px 0;

                &-brand{
                    margin-left: 24px;
                }

                &-toggler{
                    padding: 0;
                    margin-right: 24px;
                }
            }
        }

        &-list{
            padding: 0 0 45px;
        }

        &-item{
            width: auto;
            height: 211px;
            margin-right: 8px;
            margin-bottom: 8px;

            &::before{
                padding-top: (211 / 160) * 100%;
            }

            &-title{
                h3,
                p{
                    margin-left: 8px;
                    transition: all .8s ease;
                }

                .btc{
                    margin-bottom: 8px;
                }
            }

            &-btn{
                display: none;
            }

            &:hover{
                h3,
                p{
                    margin-left: 16px;
                }

                .btc{
                    margin-bottom: 16px;
                }
            }
        }

        .empty{
            img{
                margin-top: 10vh;
            }

            .no-data{
                max-width: 100%;
                margin-bottom: 10vh;
            }
        }

        .pagination{
            padding-bottom: 48px;
        }

        .modal-header{
            .close{
                svg{
                    path{
                        fill: $colorSystemWhite;
                    }
                }
            }
        }

        .filter{
            &-group{
                margin-bottom: 32px;
            }

            &-title{
                margin-bottom: 16px;
            }
        }

        .custom-control{
            min-height: 0;
            margin-bottom: 16px;

            &-input{
                width: 21px;
                height: 21px;

                &:checked{
                    ~ .custom-control-label{
                        color: $colorSystemWhite;

                        &::before{
                            background-color: $colorPrimaryBlack;
                            border-color: $colorSystemWhite;
                        }
                    }
                }

                &:focus ~ .custom-control-label::before{
                    box-shadow: none;
                }
            }

            &-label{
                padding-left: 10px;

                &::before{
                    width: 21px;
                    height: 21px;
                    background-color: $colorPrimaryBlack;
                    border: 1px solid $colorSystemWhite;
                    top: 0;
                }

                &::after{
                    width: 17px;
                    height: 17px;
                    background-size: 100%;
                    top: 2px;
                    left: -22px;
                }
            }
        }
    }
}
