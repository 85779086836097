.disclaimer{
    .single{
        &-entry{
            &-post{
                max-width: 900px;
                margin-right: auto;
                margin-left: auto;
                padding-bottom: 64px;

                @include media-breakpoint-down(md){
                    max-width: 100%;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 0;
                }
            }

            &-title{
                margin-bottom: 72px;
                text-align: center;

                @include media-breakpoint-down(md){
                    margin-bottom: 48px;
                }
            }

            article{
                .subtitle{
                    margin-bottom: 32px;

                    @include media-breakpoint-down(md){
                        margin-bottom: 8px;
                    }
                }

                p{
                    margin-bottom: 64px;

                    @include media-breakpoint-down(md){
                        margin-bottom: 32px;
                    }
                }
            }
        }
    }

    .scrolldownicon{
        bottom: 140px;
        left: 95%;
        transform: translateX(-95%);

        @include media-breakpoint-down(md){
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
