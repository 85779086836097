.header{
    .logo{
        max-width: 131px;
    }

    .navbar{
        height: 90px;
        padding: 0;

        &-brand{
            padding: 0;
            margin-left: 90px;
            margin-right: 0;
        }

        &-nav{
            max-width: 1034px;
            width: 100%;
            justify-content: space-between;
            margin-left: 6.6vw;
            margin-right: auto;

            @media (min-width: 1441px) {
                max-width: 100%;
                margin-right: 90px;
                margin-left: 10vw;
            }
        }

        &-toggler{
            border: 0;
            border-radius: 0;
            padding: 0;
            margin-right: 24px;

            &:focus{
                outline: 0;
            }
        }

        &-sticky{
            top: 0 !important;
            transition: .4s ease;
            filter: drop-shadow(0px 24px 44px rgba(0,0,0,0.25));
        }
    }

    .nav{
        &-item{
            &:last-child{
                margin-right: 0;

                ul{
                    right: 0;
                    left: unset;
                }
            }

            &.active{
                .nav-link h5{
                    color: $colorSystemWhite;
                }
            }

            &.nav-social{
                display: flex;
                flex-direction: row;
                align-items: center;

                a{
                    margin-right: 24px;
                }

                .icon{
                    width: 24px;
                }
            }
        }

        &-link{
            padding: 0;
        }

        &-collapse{
            position: relative;

            &:hover{
                ul{
                    visibility: visible;
                }

                li {
                    height: 42px;
                }
            }

            ul {
                list-style-type: none;
                position: absolute;
                left: 0;
                top: 28px;
                z-index: 100;
                background: rgba(21, 21, 21, 0.8);
                box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.25);
                padding: 45px 45px 30px;
                visibility: hidden;

                li {
                    overflow: hidden;
                    height: 0;
                    -webkit-transition: height 200ms ease-in;
                    -moz-transition: height 200ms ease-in;
                    -o-transition: height 200ms ease-in;
                    transition: height 200ms ease-in;

                    a{
                        display: block;
                        white-space: nowrap;
                        text-decoration: none;

                        &:hover{
                            color: $colorSystemWhite;
                        }
                    }
                }
            }
        }
    }

    .dropdown{
        &-toggle{
            &::after{
                display: none;
            }

            h5{
                display: flex;
                align-items: center;

                svg{
                    margin-left: 8px;
                }
            }
        }

        &-menu{
            background: rgba(21, 21, 21, 0.8);
            box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.25);
            border: 0;
            border-radius: 0;
            padding: 42px 45px;
            margin: 0;
        }

        &-item{
            padding: 0;
            margin-bottom: 16px;

            &:last-child{
                margin-bottom: 0;
            }

            &:hover{
                background-color: transparent;
                color: $colorSystemWhite;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .navbar-expand-lg .navbar-nav .nav-link{
            padding-right: 0;
            padding-left: 0;
        }
    }

    @include media-breakpoint-down(md){
        .logo{
            max-width: 88px;
        }

        .navbar{
            height: 70px;

            &-brand{
                margin-left: 24px;
            }
        }
    }
}
