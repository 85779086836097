.single{
    &-entry{
        &-post{
            padding-top: 135px;
        }

        &-header{
            margin-bottom: 135px;

            .single-entry-title{
                text-align: center;
            }

            .single-entry-desc{
                max-width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                svg{
                    margin-right: 16px;
                }
            }
        }

        &-left{
            margin-left: 75px;
        }

        &-right{
            margin-right: 75px;
        }

        &-title{
            margin-bottom: 45px;
        }

        &-desc{
            max-width: 405px;
        }

        &-content{
            max-width: 405px;
            margin-right: auto;
            margin-bottom: 48px;
        }
    }

    &-quote{
        margin-left: 75px;
        margin-right: 75px;
        padding: 53px 0 69px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        h1{
            max-width: 810px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    &-gallery{
        position: relative;
        height: 547px;
        margin: 90px 75px;

        &::before{
            display: block;
            content: "";
            width: 100%;
            padding-top: (9 / 21) * 100%;
        }

        > .single-gallery-img{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            img{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .three-column{
        padding: 0 90px;
        margin: 90px 0;

        .container-fluid{
            padding: 0;
        }

        .col{
            &:nth-child(2){
                padding: 0 45px;
            }
        }

        &-box{
            padding: 22px 45px;

            p{
                margin-bottom: 12px;
            }
        }
    }

    .location{
        width: 100%;
        height: 720px;
        position: relative;

        iframe{
            width: 100%;
            height: 100%;
        }

        &-open{
            width: 380px;
            height: 90px;
            position: absolute;
            bottom: 0;
            right: 90px;

            a{
                span{
                    margin-right: 10px;
                }
            }
        }
    }

    .scrolldownicon{
        bottom: 144px;
        left: 95%;
        transform: translateX(-95%);
    }

    .thumbnail{
        padding-top: 90px;
        padding-bottom: 90px;

        .col-md-10{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
        }

        &-header{
            margin-bottom: 135px;


            img{
                width: auto;
                height: 64px;
                margin-left: auto;
                margin-right: 751px;
            }
        }

        &-left{
            max-width: 405px;
            margin-right: 58px;
            margin-left: auto;
        }

        &-right{
            max-width: 564px;
            margin-right: 75px;
        }
    }

    @include media-breakpoint-down(md){
        &-entry{
            &-post{
                padding-top: 48px;
            }

            &-header{
                margin-bottom: 48px;

                .single-entry-title{
                    margin-bottom: 8px;
                }

                .single-entry-desc{
                    max-width: 255px;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;

                    svg{
                        display: none;
                    }
                }
            }

            &-left{
                margin-left: 5px;
                margin-right: 5px;
                margin-bottom: 32px;
                text-align: center;

                h1{
                    margin-bottom: 32px;
                }
            }

            &-right{
                margin-left: 5px;
                margin-right: 5px;
            }

            .three-column{
                padding: 0 20px;

                .col{
                    flex-basis: 1;

                    &:nth-child(2){
                        padding: 0;
                    }
                }

                .row{
                    flex-direction: column;
                }

                &-box{
                    padding: 9px 10px 16px;
                    margin-bottom: 16px;

                    p{
                        margin-bottom: 0;
                    }
                }
            }

            .location{
                height: 100vh;

                &-open{
                    width: 220px;
                    height: 60px;
                    right: 0;
                    left: 0;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        &-quote{
            margin-left: 6px;
            margin-right: 6px;
            padding: 23px 0;

            h1{
                max-width: 100%;
            }
        }

        &-gallery{
            height: 145px;
            margin: 48px 6px;

            &::before{
                padding-top: (145 / 333) * 100%;
            }
        }

        .scrolldownicon{
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
        }

        .thumbnail{
            .col-md-10{
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            &-header{
                margin-bottom: 32px;

                img{
                    width: auto;
                    height: 40px;
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            &-left{
                max-width: 100%;
                margin-right: 20px;
                margin-left: 20px;
                margin-bottom: 32px;

                &-content{
                    text-align: center;
                }
            }

            &-right{
                max-width: 100%;
                margin-right: 6px;
                margin-left: 6px;
            }
        }
    }
}
