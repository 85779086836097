.modal{
    &-dialog{
        margin: 0;

        &-scrollable{
            max-height: 100%;

            .modal-content{
                max-height: 100%;
            }
        }
    }

    &-content{
        height: 100vh;
        background-color: $colorPrimaryBlack;
        border: 0;
        border-radius: 0;
    }

    &-header{
        border: 0;
        border-radius: 0;
        padding: 24px 32px 16px;

        .close:focus{
            outline: 0;
        }
    }

    &-body{
        padding: 0 32px;
    }

    .navbar{
        &-nav{
            margin-right: 0;
        }
    }

    .nav{
        &-item{
            margin-bottom: 24px;

            &.nav-social{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                a{
                    margin: 0 12px;
                }

                .icon{
                    width: 24px;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }

        &-link{
            h5{
                display: flex;

                svg{
                    margin-left: auto;
                }
            }

            &:focus{
                outline: 0;
            }
        }
    }

    .dropdown{
        &-item{
            &:first-child{
                margin-top: 16px;
            }

            &:active{
                background-color: transparent;
            }

            &:focus{
                color: $colorSystemWhite;
                background-color: transparent;
                outline: 0;
            }

            &:hover{
                background-color: transparent;
                color: $colorSystemWhite;
            }
        }
    }
}

#modal_full_ehat{
    img{
        &.modal-content-ehat {
            border-radius: 5px;
            cursor: pointer;
            -webkit-animation-name: zoom;
            -webkit-animation-duration: 0.5s;
            animation-name: zoom;
            animation-duration: 0.5s;
            height: 100%;
            max-width: 95%;
            object-fit: contain;
            padding-top: 70px;
            padding-bottom: 70px;
        }
    }

    &:hover {
        > .button-np button {
            opacity: 1;
        }
    }
}

.detail-masterpieces, .progress-site{
    .modal{
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.9);
        text-align: center;

        .span_close {
            position: absolute;
            top: 15px;
            right: 35px;
            color: #f1f1f1;
            font-size: 40px;
            font-weight: bold;
            transition: 0.3s;

            &:hover{
                color: #bbb;
                text-decoration: none;
                cursor: pointer;
            }

            &:focus{
                color: #bbb;
                text-decoration: none;
                cursor: pointer;
            }
        }

        .button-np{
            button{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background: transparent;
                border: none;
                opacity: 0;
                transition: opacity .8s ease;
                cursor: pointer;

                svg{
                    width: 2.4vw;
                    height: auto;

                    path{
                        fill: $colorSystemWhite;
                    }
                }

                &:focus{
                    outline: 0;
                }

                &:active{
                    color: #828282;
                }

                &:hover{
                    opacity: 1;
                }

                &.owl-next{
                    right: 10px;
                    transform: rotate(-90deg);
                }

                &.owl-prev {
                    left: 10px;
                    transform: rotate(90deg);
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .modal{
        &-dialog{
            max-width: 100% !important;
        }

        .nav{
            &-item{
                margin-right: 0;

                &.active{
                    h5{
                        color: $colorSystemWhite;
                    }
                }
            }
        }
    }

    #modal_full_ehat{
        img{
            &.modal-content-ehat {
                max-width: 85%;
                width: 85%;
                height: auto;
                position: absolute;
                top: 35%;
                left: 7.5%;
            }
        }
    }

    .detail-masterpieces, .progress-site{
        .modal{
            .button-np{
                button{
                    opacity: 1;
                    top: 48.5%;

                    svg{
                        width: 32px;
                    }
                }
            }
        }
    }
}
