.detail-masterpieces{
    .scrolldownicon{
        bottom: 144px;
        left: 95%;
        transform: translateX(-95%);
    }

    .hero-wrap{
        &.video{
            .scrolldownicon{
                bottom: 14%;
            }
        }
    }

    &-slider{
        .gallery{
            max-width: 842px;
            height: 180px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            transform: translateY(-90px);
            filter: drop-shadow(0px 24px 44px rgba(0, 0, 0, 0.25));

            .item{
                width: 270px;
                height: 180px;
            }

            .event-click-view{
                cursor: pointer;
            }
        }

        .owl-carousel{
            .owl-nav{
                display: block;

                button{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45px;
                    height: 45px;
                    background-color: $colorSystemWhite;
                    box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.25) !important;
                }

                .owl-prev{
                    position: absolute;
                    left: -23px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .owl-next{
                    position: absolute;
                    right: -23px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .no-gallery{
            height: 120px;
        }
    }

    .article{
        &-sidebar{
            padding-left: 75px;

            h1{
                margin-bottom: 27px;
            }

            &-tag{
                svg{
                    margin-right: 16px;
                    margin-top: 12px;
                }
            }
        }

        &-content{
            margin-bottom: 23vh;

            &-entry{
                max-width: 405px;
                margin-bottom: 32px;
            }

            &-social{
                margin-bottom: 64px;

                ul{
                    li{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        width: 64px;
                        height: 64px;
                        margin-right: 8px;
                    }
                }
            }

            &-link{
                display: flex;
                position: relative;
                z-index: 1;
            }
        }

        &-quote{
            margin-left: 75px;
            margin-right: 75px;
            padding: 53px 0 69px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            h1{
                max-width: 810px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }
        }

        &-stats{
            padding-left: 75px;
            padding-right: 75px;
            margin-top: 90px;
            margin-bottom: 90px;

            &-box{
                padding: 22px 45px;

                p{
                    margin-bottom: 13px;
                }
            }
        }

        .location{
            width: 100%;
            height: 720px;
            position: relative;

            iframe{
                width: 100%;
                height: 100%;
            }

            &-open{
                width: 380px;
                height: 90px;
                position: absolute;
                bottom: 0;
                right: 90px;

                a{
                    span{
                        margin-right: 10px;
                    }
                }
            }
        }

        .back-to{
            position: relative;
            z-index: 1;

            a{
                margin-top: 86px;
                margin-bottom: 86px;
            }
        }

        .related{
            padding-left: 90px;
            padding-right: 90px;

            &-title{
                padding-top: 90px;
                margin-bottom: 58px;
            }

            &-list{
                padding-bottom: 90px;
                margin-left: 0;
                margin-right: 0;
            }

            .masterpieces-item{
                &::before{
                    padding-top: (358 / 281) * 100%;
                }

                &:hover{
                    .rtl{
                        &::before{
                            width: 67%;
                        }
                    }
                }

                &-title{
                    h3,
                    p{
                        margin-left: 32px;
                    }

                    .btc{
                        margin-bottom: 57px;
                    }
                }

                &-btn{
                    .rtl{
                        padding-left: 40px;
                    }
                }
            }

            .item-mortgage{
                background-color: $colorPrimaryBlack;

                .masterpieces-item-bg{
                    width: 92px;
                    height: 92px;
                    top: 30%;
                    left: 50%;
                    transform: translateX(-50%);

                    img{
                        object-fit: contain;
                    }

                    .bg-gradiant{
                        display: none;
                    }
                }

                .rtl{
                    span{
                        color: $colorPrimaryBlack;
                    }

                    &::before{
                        background-color: $colorSystemBlack10;
                    }
                }

                &:hover{
                    .masterpieces-item-bg{
                        img{
                            transform: scale(1);
                        }
                    }

                    .rtl{
                        &::before{
                            background-color: $colorSystemBlack10;
                        }
                    }

                    .arrow{
                        background-color: $colorSystemBlack10;

                        svg{
                            path{
                                fill: $colorPrimaryBlack;
                            }
                        }
                    }
                }
            }

            .owl-carousel{
                .owl-stage-outer{
                    overflow: visible;
                }
            }
        }
    }

    @-webkit-keyframes zoom {
        from {
            -webkit-transform: scale(0)
        }
        to {
            -webkit-transform: scale(1)
        }
    }

    @keyframes zoom {
        from {
            transform: scale(0)
        }
        to {
            transform: scale(1)
        }
    }

    @include media-breakpoint-down(md){
        .scrolldownicon{
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
        }

        .hero-wrap{
            &.video{
                .scrolldownicon{
                    bottom: 10px;
                }
            }
        }

        &-slider{
            .container-fluid{
                padding-left: 0;
                padding-right: 0;
            }

            .gallery{
                background-color: $colorSystemBlack70;
                height: auto;
                padding: 32px 0;
                transform: translateY(0);
            }

            .owl-carousel{
                .owl-item{
                    height: 130px;
                    margin-top: 15px;

                    &.active{
                        height: 160px;
                        margin-top: 0;
                    }

                    .item{
                        width: 100%;
                        height: 100%;

                        img{
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }

                .owl-nav{
                    display: none;
                }

                .owl-dots{
                    text-align: center;
                    padding: 24px 0 0;
                }

                .owl-dot{
                    margin-right: 8px;

                    &:last-child{
                        margin-right: 0;
                    }

                    &.active{
                        span{
                            width: 8px;
                            height: 8px;
                            background-color: #FFFFFF;
                            border-radius: 4px;
                        }
                    }

                    span{
                        display: block;
                        width: 6px;
                        height: 6px;
                        background-color: #8C8C8C;
                        border-radius: 3px;
                    }
                }
            }

            .no-gallery{
                display: none;
            }
        }

        .article{
            &-sidebar{
                padding-left: 0;
                margin: 48px 0 32px;
                text-align: center;

                &-tag{
                    align-items: center !important;
                    justify-content: center;

                    svg{
                        display: none;
                    }
                }

                h1{
                    margin-bottom: 8px;
                }
            }

            &-content{
                padding-left: 15px;
                padding-right: 15px;
                margin-bottom: 64px;
                text-align: center;

                &-entry{
                    margin-bottom: 32px;
                    text-align: center;
                }

                &-social{
                    margin-bottom: 32px;

                    ul{
                        justify-content: center !important;
                    }
                }
            }

            &-quote{
                margin-left: 5px;
                margin-right: 5px;
                padding: 24px 0;

                h1{
                    text-align: center;
                }
            }

            &-stats{
                padding-left: 0;
                padding-right: 0;
                margin: 32px 0;

                .col{
                    flex-basis: auto;
                    padding: 0 20px;
                }

                &-box{
                    padding: 8px 10px 16px;
                    margin-bottom: 16px;

                    p{
                        margin-bottom: 0;
                    }
                }
            }

            .location{
                height: 100vh;

                &-open{
                    width: 220px;
                    height: 60px;
                    right: 0;
                    left: 0;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .back-to{
                a{
                    margin-top: 48px;
                    margin-bottom: 48px;
                }

                .button{
                    width: 70%;
                    padding: 16px !important;
                }

            }

            .related{
                padding-left: 0;
                padding-right: 0;

                &-title{
                    padding-top: 48px;
                    margin-bottom: 24px;
                    padding-left: 20px;
                    padding-right: 20px;

                    h3{
                        text-align: center;
                    }
                }

                &-list{
                    padding-left: 20px;

                    .col-6{
                        padding: 0;
                    }
                }

                .masterpieces-item{
                    width: 143px;
                    height: 181px;
                    margin: 0;

                    &-title{
                        h3,
                        p{
                            margin-left: 8px;
                        }

                        .btc{
                            margin-bottom: 8px;
                        }
                    }

                    &:hover{
                        h3,
                        p{
                            margin-left: 16px;
                        }

                        .btc{
                            margin-bottom: 16px !important;
                        }
                    }
                }

                .item-mortgage{
                    .masterpieces-item-bg{
                        width: 63px;
                        height: 63px;
                        transform: translate(0, 0);
                        left: 40px;
                        top: 20px;
                    }

                    &:hover{
                        .masterpieces-item-bg{
                            img{
                                transform: scale(1);
                            }
                        }
                    }
                }

                .owl-carousel{
                    .owl-stage-outer{
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
