.post{
    &-entry{
        margin-top: 90px;

        .container{
            @include media-breakpoint-up(lg){
                max-width: 900px;
            }
            @include media-breakpoint-up(xl){
                max-width: 900px;
            }
        }
    }

    &-header{
        padding: 96px 0;
        text-align: center;
    }

    &-title{
        margin-bottom: 27px;
    }

    &-date{
        margin-bottom: 27px;
    }

    &-project{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        svg{
            margin-right: 16px;
        }
    }

    &-body{
        padding-bottom: 135px;

        figure{
            display: block;
            max-width: 100%;
            width: 600px;
            margin: 0 auto;
            text-align: center;

            figcaption{
                font-size: 16px;
                line-height: 28px;
                color: $colorSystemBlack50;
                font-style: italic;
                font-weight: 700;
                margin-bottom: 16px;
                text-align: left;

                p{
                    font-style: italic;
                }
            }
        }

        img{
            max-width: 100%;
            height: auto;
            margin-bottom: 16px;
        }

        h2{
            font-family: $fontSecondary;
            font-weight: 700;
            line-height: 43px;
            letter-spacing: 0.03em;
            margin-bottom: 32px;
            padding-top: 16px;
        }

        h3{
            font-family: $fontSecondary;
            font-weight: 700;
            letter-spacing: 0.03em;
            margin-bottom: 32px;
            padding-top: 16px;
        }

        p{
            font-size: 16px;
            line-height: 26px;
            color: $colorSystemBlack50;
            margin-bottom: 16px;

            a{
                text-decoration: underline;
            }

            img{
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        ul{
            padding-left: 16px;

            li{
                color: $colorSystemBlack50;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: -0.01em;
                margin-bottom: 24px;

                &::before {
                    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                    color: $colorSystemBlack50; /* Change the color */
                    font-weight: bold; /* If you want it to be bold */
                    display: inline-block; /* Needed to add space between the bullet and the text */
                    width: 1em; /* Also needed for space (tweak if needed) */
                    margin-left: -1em; /* Also needed for space (tweak if needed) */
                }
            }
        }
    }

    @include media-breakpoint-down(md){
        &-entry{
            margin-top: 70px;
        }

        &-header{
            padding: 48px 0 64px;
            margin-left: 21px;
            margin-right: 21px;
        }

        &-title{
            margin-bottom: 16px;
        }

        &-date{
            margin-bottom: 16px;
        }

        &-project{
            svg{
                display: none;
            }

            p{
                text-align: center;
            }
        }

        &-body{
            padding-bottom: 64px;
            margin: 0 6px;

            figure{
                width: 100%;

                figcaption{
                    font-size: 13px;
                    line-height: 20px;
                }
            }

            img{
                width: 100%;
                height: auto !important;
            }

            h2{
                font-size: 20px;
                line-height: 28px;
            }

            h3{
                font-size: 16px;
                line-height: 26px;
            }

            p{
                font-size: 13px;
                line-height: 22px;
            }

            ul{
                li{
                    font-size: 13px;
                    line-height: 22px;
                    margin-bottom: 16px;
                }
            }
        }
    }
}
