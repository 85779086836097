// Color
$colorPrimaryBlack: #151515;
$colorSystemBlack70: #5B5B5B;
$colorSystemBlack60: #737373;
$colorSystemBlack50: #8C8C8C;
$colorSystemBlack10: #E8E8E8;
$colorSystemLight: #F9F9F9;
$colorSystemWhite: #FFFFFF;

// Fonts
$fontPrimary: 'Raleway',  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
$fontSecondary: 'Playfair Display',  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
