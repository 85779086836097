h1{
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;

    &.serif{
        font-family: $fontSecondary;
    }

    &.italic{
        font-style: italic;
        font-weight: 400;
    }

    @include media-breakpoint-down(md){
        font-size: 24px;
        line-height: 39px;
    }
}

h2{
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;

    &.serif{
        font-family: $fontSecondary;
        font-weight: 700;
        line-height: 43px;
        letter-spacing: 0.03em;
    }

    @include media-breakpoint-down(md){
        font-size: 20px;

        &.serif{
            line-height: 28px;
        }
    }
}

h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;

    &.serif{
        font-family: $fontSecondary;
        font-weight: 700;
        letter-spacing: 0.03em;
    }

    &.italic{
        font-style: italic;
        font-weight: 700;
        letter-spacing: 0.02em;
    }

    @include media-breakpoint-down(md){
        font-size: 16px;
        line-height: 26px;
    }
}

h4 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    &.serif{
        font-family: $fontSecondary;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.03em;
    }

    &.no-data{
        color: #8C8C8C;
    }
}

h5{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.01em;
    text-transform: capitalize;
}

p{
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;

    &.small{
        font-size: 10px;
        font-weight: 600;
        line-height: 17px;
    }

    &.serif{
        font-family: $fontSecondary;
        font-weight: 700;
        letter-spacing: 0.03em;
    }
}

.title{
    font-family: $fontSecondary;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 96px;
    text-transform: capitalize;

    @include media-breakpoint-down(md){
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.03em;
    }
}

.body1{
    &:not(.small) p{
        margin-bottom: 26px;
    }
    &, & > p{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.01em;

        &.italic{
            font-style: italic;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0em;
        }

        &.small, &.small > p{
            font-size: 10px;
            font-weight: 600;
            line-height: 17px;
        }

        @include media-breakpoint-down(md){
            font-size: 13px;
            line-height: 22px;
        }
    }
}
