.customers{
    .single{
        &-entry{
            &-header{
                padding: 135px 0;
                margin-bottom: 0;
            }
        }
    }

    .collection{
        &-list{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 0 90px;
            padding-bottom: 135px;

            &.empty{
                padding-top: 135px;

                .col{
                    text-align: center;
                }
            }
        }
    }

    .masterpieces-item{
        max-width: 281px;
        height: 358px;
        border: 1px solid $colorSystemBlack10;
        margin-right: 45px;
        overflow: inherit;

        &:last-child{
            margin-right: 0;
        }

        &::before{
            padding-top: (358 / 281) * 100%;
        }

        &:hover{
            border: 1px solid transparent;

            .masterpieces-item-bg{
                transform: none;
            }

            .rtl{
                &::before{
                    width: 67%;
                }
            }

            .btc{
                margin-bottom: 84px !important;
            }
        }

        &-title{
            h3,
            p{
                margin-left: 32px;
            }

            .btc{
                margin-bottom: 57px;
            }
        }

        &-btn{
            left: 1px;
            bottom: -1px;

            .rtl{
                padding-left: 48px;
            }
        }
    }

    .progress-site{
        &-list{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            padding-bottom: 135px;

            &.empty{
                .col{
                    text-align: center;
                }
            }
        }

        &-item{
            display: block;
            max-width: 281px;
            margin-right: 45px;

            &:last-child{
                margin-right: 0;
            }
        }

        .progress-update{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;

            p{
                color: $colorSystemWhite;
                opacity: .5;
            }
        }

        .progress{
            height: 10px;
            background-color: $colorSystemBlack60;
            border-radius: 0;

            &-bar{
                background-color: $colorSystemBlack50;
            }
        }

        .card{
            border: 0;
            border-radius: 0;

            &-img-top{
                height: 180px;
                object-fit: contain;
                border-radius: 0;
            }

            &-body{
                padding: 24px;
            }

            &-desc{
                display: flex;
                align-items: center;
                justify-content: flex-start;

                svg{
                    margin-right: 16px;
                }

                p{
                    margin-left: 0;
                }
            }

            &-footer{
                padding: 16px 24px;
                border-radius: 0;
            }
        }
    }

    .apps{
        position: relative;

        .single-entry-title{
            margin-bottom: 64px;
            text-transform: uppercase;
        }

        .single-entry-desc{
            max-width: 660px;
            margin: 0 auto;
        }

        &-left{
            position: relative;
            height: 585px;

            .row{
                flex-direction: row;
                justify-content: flex-start;
                height: 100%;

                .col-md-6{
                    &:last-child{
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }

            .apps-img{
                img{
                    margin-left: auto;
                    margin-right: 233px;
                }

                &::after{
                    right: 340px;
                }
            }
        }

        &-right{
            position: relative;
            height: 600px;

            .row{
                flex-direction: row;
                justify-content: flex-start;
                height: 100%;

                .col-md-6{
                    &:first-child{
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }

            .apps-content{
                margin-left: auto;
                margin-right: 225px;
            }

            .apps-img{
                img{
                    margin-left: 105px;
                    margin-right: auto;
                }

                &::after{
                    left: 340px;
                }
            }
        }

        &-content{
            max-width: 405px;

            h2{
                margin-bottom: 45px;
            }

            p{
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: -0.01em;
                color: $colorSystemBlack50;

                &.url{
                    color: $colorPrimaryBlack !important;
                    margin-top: 64px;
                }
            }

            hr{
                margin: 64px 0;
                border-top: 1px solid $colorSystemBlack50;
            }

            a{
                text-decoration: underline;

                &:hover{
                    color: $colorPrimaryBlack;
                }
            }
        }

        &-img{
            position: absolute;
            top: -55px;
            width: 100%;

            img{
                display: block;
                max-width: 380px;
                max-height: 380px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: drop-shadow(0px 20px 44px rgba(0, 0, 0, 0.32));
                position: relative;
                z-index: 10;
            }

            &::after{
                display: block;
                content: "";
                position: absolute;
                top: 55px;
                background-color: $colorSystemBlack10;
                width: 380px;
                height: 380px;
                z-index: 1;
            }
        }
    }

    .faq{
        .single-entry-header{
            padding-bottom: 90px;
        }

        &-list{
            margin: 0 90px;
            padding-bottom: 90px;

            &-group{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                max-width: 100%;
                padding: 0 45px 90px;
                margin-bottom: 90px;
                background-color: $colorSystemWhite;
                box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.08);
            }
        }

        &-category{
            width: 100%;
            padding: 45px 0;
            border-bottom: 1px solid $colorSystemBlack10;
        }

        &-item{
            width: 100%;
            padding: 32px 0;
            border-bottom: 1px solid $colorSystemBlack10;

            &-title{
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.collapsed {
                    .plus-minus-toggle{
                        &:after {
                          transform: rotate(90deg);
                        }
                        &:before {
                          transform: rotate(180deg);
                        }
                    }
                }

                h4{
                    cursor: pointer;
                }

                .plus-minus-toggle {
                    position: relative;
                    height: 24px;
                    width: 24px;
                    cursor: pointer;

                    &:before,
                    &:after{
                        background: $colorSystemBlack50;
                        content: '';
                        width: 23px;
                        height: 5px;
                        position: absolute;
                        top: 10px;
                        left: 0;
                        transition: transform 400ms ease;
                    }

                    &:after {
                        transform-origin: center;
                    }
                }
            }

            &-content{
                width: 100%;
                padding-left: 45px;

                div{
                    padding-top: 45px;
                    margin-bottom: 16px;
                }
            }
        }
    }

    @include media-breakpoint-down(md){
        .single{
            &-entry{
                &-header{
                    padding: 48px 0 32px;
                    margin-bottom: 0;
                }
            }
        }

        .collection{
            &-list{
                flex-wrap: wrap;
                margin: 0 21px;
                padding-bottom: 32px;

                &.empty{
                    padding-bottom: 135px;
                }
            }
        }

        .masterpieces-item{
            max-width: 163px;
            width: 100%;
            height: 211px;
            margin-right: auto;
            margin-left: auto;

            &:last-child{
                margin-right: auto;
            }

            &::before{
                padding-top: (211 / 163) * 100%;
            }

            &:hover{
                .btc{
                    margin-bottom: 8px !important;
                }
            }

            &-title{
                h3,
                p{
                    margin-left: 8px;
                }

                .btc{
                    margin-bottom: 8px;
                }
            }
        }

        .progress-site{
            &-list{
                flex-wrap: wrap;
                padding-bottom: 48px;
                margin: 0 21px;

                &.empty{
                    padding-top: 96px;
                    padding-bottom: 96px;

                    .col{
                        text-align: center;
                    }
                }
            }

            &-item{
                display: block;
                max-width: calc((100% / 2) - 8px);
                margin-right: 0;
                margin-left: 0;
                margin-bottom: 16px;

                &:last-child{
                    margin-right: 0;
                }

                &:nth-child(odd){
                    margin-right: auto !important;
                }

                &:nth-child(even){
                    margin-left: auto !important;
                }
            }

            .progress-update{
                margin-bottom: 8px;
            }

            .card{
                margin-bottom: 0;

                &-img-top{
                    height: auto;
                }

                &-body{
                    text-align: left;
                    padding: 16px 8px;
                }

                &-footer{
                    padding: 10px 8px;
                    border-radius: 0;
                }
            }
        }

        .apps{
            .single-entry-title{
                margin-bottom: 8px;
            }

            .single-entry-desc{
                max-width: 660px;
                margin: 0 auto;
            }

            &-left{
                height: auto;
                padding: 0 0 24px;

                .apps-img{
                    img{
                        margin-left: 0;
                    }
                }
            }

            &-right{
                height: auto;
                padding: 48px 0;

                .row{
                    flex-direction: column-reverse;
                }

                .apps-content{
                    margin-left: 0;
                    margin-right: 0;
                }

                .apps-img{
                    img{
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }

            &-content{
                max-width: 100%;
                margin: 0 21px !important;

                h2{
                    margin-bottom: 32px;
                    margin-top: 32px;
                    margin-left: 26.5px;
                    margin-right: 26.5px;
                    text-align: center;
                }

                p{
                    text-align: center;
                    margin-left: 26.5px;
                    margin-right: 26.5px;

                    &.url{
                        margin-top: 32px;
                    }
                }

                hr{
                    margin: 32px 0;
                }
            }

            &-img{
                position: relative;
                top: 0;
                width: 100%;

                img{
                    max-width: 100%;
                    max-height: 100%;
                    padding: 0 21px;
                }

                &::after{
                    display: none;
                }
            }
        }

        .faq{
            .single-entry-header{
                padding-bottom: 32px;
            }

            &-list{
                margin: 0 21px;
                padding-bottom: 32px;

                &-group{
                    padding: 0 32px 64px;
                    margin-bottom: 32px;
                }
            }

            &-category{
                padding: 32px 0;
            }

            &-item{
                &-title{
                    h4{
                        width: 80%;
                        font-size: 14px;
                    }

                    .plus-minus-toggle {
                        height: 20px;
                        width: 20px;

                        &:before,
                        &:after{
                            width: 19px;
                            height: 4px;
                            top: 8px;
                        }
                    }
                }

                &-content{
                    padding-left: 21px;

                    div{
                        padding-top: 32px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
