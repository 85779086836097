body {
    font-family: $fontPrimary;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: $colorPrimaryBlack;
    background-color: $colorPrimaryBlack;
}

h1, h2, h3, h4, h5, p, a, label, span, button{
    margin: 0;
    padding: 0;
}

button:focus{
    outline: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
}

a{
    color: initial;
}

a:hover{
    text-decoration: none;
    color: initial;
}

ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
