.card{
    border: 0;
    border-radius: 0;
    background: transparent;
    margin-bottom: 96px;

    &-body{
        padding: 32px 0;

        p{
            margin-left: 32px;
        }
    }

    &-img{
        &-top{
            border-radius: 0;
        }
    }

    &-title{
        display: flex;
        align-items: center;

        svg{
            margin-right: 16px;
        }
    }

    @include media-breakpoint-down(md){
        margin-bottom: 64px;

        &-body{
            padding: 16px 0 0;
            text-align: center;

            p{
                margin-left: 0;
            }
        }

        &-title{
            justify-content: center;

            svg{
                display: none;
            }
        }
    }
}
