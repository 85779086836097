.footer{
    .container-fluid{
        padding-left: 0;
        padding-right: 0;
    }

    .logo{
        max-width: 190px;
    }

    &-top{
        padding: 120px 90px;

        .footer-menu-group{
            margin-right: 80px;

            &:last-child{
                margin-right: 0;
            }

            .footer-social{
                display: flex;
                justify-content: space-between;

                a {
                    display: inline-block;

                    .icon{
                        display: inline;
                        max-height: 32px;
                        max-width: 32px;
                    }
                }
            }
        }

        .menu-title{
            margin-bottom: 36px;
        }

        ul{
            li{
                margin-bottom: 8px;
            }
        }
    }

    &-bottom{
        padding: 48px 90px;

        &-menu a{
            margin-right: 64px;
        }
    }

    @include media-breakpoint-down(md){
        .logo{
            max-width: 115px;
        }

        &-top{
            padding: 64px 24px 16px;

            .col-md-5{
                justify-content: center !important;
                margin-bottom: 48px;
            }

            .col-md-7{
                flex-direction: column;
                align-items: center !important;
            }

            .footer-menu-group{
                margin-right: 0;
                margin-bottom: 32px;
                text-align: center;
            }

            .menu-title{
                margin-bottom: 16px;
            }
        }

        &-bottom{
            padding: 0 24px 64px;

            .col-md-6{
                justify-content: space-between !important;
                margin-left: 24px;
                margin-right: 24px;
                text-align: center;
            }

            &-menu{
                margin-bottom: 48px;

                a{
                    margin-right: 0;
                }
            }
        }

        .icon{
            max-width: 24px;
            max-height: 24px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
