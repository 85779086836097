.about-us{
    .hero{
        &-wrap{
            &::before{
                padding-top: (9 / 16) * 100%;
            }
        }
    }

    .team{
        &-list{
            max-width: 960px;
            margin: 0 auto;

            .row-cols-md-3{
                margin-left: -45px;
                margin-right: -45px;

                .col{
                    padding: 0 45px;
                    text-align: center;

                    .card{
                        max-width: 260px;
                        margin: 0 auto 96px;

                        .card-body{
                            padding-top: 16px;
                            padding-bottom: 0px;

                            p{
                                text-align: left;
                                margin-left: 32px;
                            }
                        }

                    }
                }
            }
        }
    }

    

    .history{
        .content-header{
            text-align: left;

            &-desc{
                justify-content: flex-start;
            }
        }

        &-img{
            max-width: 540px;
            max-height: 655px;
            height: 100%;
            position: relative;

            &::after{
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                max-height: 540px;
                background: $colorSystemLight;
                position: absolute;
                top: 90px;
            }

            img{
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                filter: drop-shadow(0px 20px 44px rgba(0, 0, 0, 0.32));
                z-index: 1;
            }
        }

        &-content{
            padding-bottom: 135px;
        }

        .timeline-left{
            .history-img{
                margin-right: 90px;
                margin-left: auto;

                &::after{
                    right: -90px;
                }
            }
        }

        .timeline-right{
            .history-img{
                margin-right: auto;
                margin-left: 90px;

                &::after{
                    left: -90px;
                }
            }
        }
    }

    .timeline{
        align-items: center;
        padding-bottom: 90px;

        &-list{
            max-width: 495px;
            margin: 0 auto;
            position: relative;

            &::before{
                content: "";
                display: block;
                width: 1px;
                height: calc( 100% - 80px );
                background: $colorSystemBlack50;
                position: absolute;
                top: 20px;
                left: 0;
            }

            li{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                padding-left: 60px;
                margin-bottom: 60px;

                &:last-child{
                    margin-bottom: 0;
                }
            }

            h3{
                position: relative;

                &::before{
                    content: "";
                    display: block;
                    width: 12px;
                    height: 12px;
                    background: $colorSystemBlack50;
                    position: absolute;
                    left: -65px;
                    top: 18px;
                }
            }

            p{
                width: 280px;
            }
        }
    }

    .brand{
        .content-header{
            margin-left: 122px;
            margin-right: 122px;
            border-bottom: 1px solid $colorSystemBlack10;

            &-title{
                margin-bottom: 90px;
            }

            &-desc{
                width: 90%;
                margin: 0 auto;
            }
        }

        &-core{
            padding-bottom: 71px;

            .content-header{
                border-bottom: 0;

                &-title{
                    margin-bottom: 45px;
                }
            }

            &-list{
                padding: 0 122px;
            }

            .card{
                margin-bottom: 84px;

                &-body{
                    max-width: 320px;
                    padding: 0;

                    p{
                        margin-left: 0;
                    }
                }

                &-title{
                    margin-bottom: 32px;
                }
            }
        }
    }

    .media{
        display: flex;
        flex-direction: column;
        align-items: unset;
        padding-bottom: 90px;

        &-content{
            padding: 0 75px;
        }

        &-filter{
            .form{
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 50px 19px 32px 32px;
                background-color: $colorSystemLight;
                position: relative;
                z-index: 10;

                h3{
                    margin-bottom: 32px;
                }

                .navbar-toggler{
                    display: none;
                }

                .customselect{
                    position: relative;
                    width: 100%;
                    margin-bottom: 32px;

                    .dropdown{
                        width: 100%;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }

        &-list{
            border: 1px solid $colorSystemBlack10;

            &-header{
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 35px 32px;
                background-color: $colorSystemBlack10;

                p{
                    &:first-child{
                        width: 80%;
                    }

                    &:last-child{
                        width: 20%;
                    }
                }
            }

            &-item{
                li{
                    border-bottom: 1px solid $colorSystemBlack10;

                    &:last-child{
                        border-bottom: 0;
                    }

                    a{
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        justify-content: flex-start;
                        padding: 19px 32px;

                        &:hover{
                            background-color: $colorSystemLight;
                        }
                    }

                    p{
                        &:first-child{
                            width: 80%;
                            padding-right: 32px;
                        }

                        &:last-child{
                            width: 20%;
                        }
                    }
                }
            }

            .empty{
                padding-top: 128px;
                padding-bottom: 128px;

                img{
                    max-width: 120px;
                    height: auto;
                }

                .no-data{
                    max-width: 400px;
                    margin: 0 auto;
                    font-size: 18px;
                }
            }
        }

        .pagination{
            padding: 45px 0 90px;

            &-item{
                width: 56px;
                height: 56px;

                &.active{
                    background-color: $colorSystemBlack10;
                }

                a{
                    &:hover{
                        color: initial;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .content{
        &-header{
            padding: 135px 0 90px;
            margin-left: 90px;
            margin-right: 90px;
            text-align: center;

            &-title{
                margin-bottom: 32px;
            }

            &-desc{
                display: flex;
                align-items: center;
                justify-content: center;

                svg{
                    margin-right: 16px;
                }
            }
        }
    }

    .single-entry-post{
        p{
            a{
                text-decoration: underline;

                &:hover{
                    color: initial;
                }
            }
        }
    }

    @include media-breakpoint-down(md){
        .hero{
            &-wrap{
                height: 530px;

                &::before{
                    padding-top: (106 / 75) * 100%;
                }

                &.video{
                    height: auto;
                }
            }
        }

        .single-entry-content{
            max-width: 100%;
            margin-left: 20px;
            margin-right: 20px;
            text-align: center;
        }

        .team{
            &-list{
                padding: 0 21px;

                .row-cols-md-3{
                    margin-left: 0;
                    margin-right: 0;

                    .col{
                        padding: 0 15px;

                        p{
                            text-align: center;
                        }
                    }
                }
            }
        }

        .history{
            .content-header{
                text-align: center;

                &-desc{
                    justify-content: center;
                }
            }

            &-timeline{
                padding: 0 21px;
                margin-bottom: 64px;
            }

            &-img{
                max-width: 100%;
                max-height: 400px;
                position: relative;

                &::after{
                    display: none;
                }
            }

            &-content{
                padding-bottom: 0;
            }

            .timeline-left,
            .timeline-right{
                .history-img{
                    margin-right: 21px;
                    margin-left: 21px;
                }
            }

            .timeline-right{
                flex-direction: column-reverse;
            }
        }

        .timeline{
            &-list{
                max-width: 100%;

                &::before{
                    top: 15px;
                }

                li{
                    padding-left: 30px;
                }

                h3{
                    position: relative;
                    max-width: 25%;

                    &::before{
                        left: -35px;
                        top: 10px;
                    }
                }

                p{
                    width: 221px;
                    line-height: 20px;
                }
            }
        }

        .brand{
            .content-header{
                margin-left: 21px;
                margin-right: 21px;
                border-bottom: 1px solid $colorSystemBlack10;
                padding: 32px 0;

                &-title{
                    margin-bottom: 32px;
                }

                &-desc{
                    width: 100%;
                }
            }

            &-mission{
                .content-header-desc{
                    h3{
                        font-weight: 600;
                        font-size: 13px;
                        font-style: normal;
                        line-height: 22px;
                        letter-spacing: -0.01em;
                    }
                }
            }

            &-core{
                padding-bottom: 64px;

                .content-header{
                    border-bottom: 0;

                    &-title{
                        margin-bottom: 0;
                    }
                }

                &-list{
                    padding: 0 47.5px;
                }

                .card{
                    margin-bottom: 32px;

                    &-body{
                        max-width: 100%;
                    }

                    &-title{
                        margin-bottom: 16px;
                    }
                }
            }
        }

        .media{
            &-content{
                padding: 0 6px;
            }

            &-filter{
                margin-bottom: 32px;

                .form{
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 16px 24px;

                    h3{
                        margin-bottom: 0;
                    }

                    .navbar-toggler{
                        display: block;
                        padding: 0;

                        &:focus{
                            border: 1px solid transparent;
                        }
                    }

                    &-row{
                        display: none;
                    }

                    .button{
                        display: none;
                    }
                }
            }

            &-list{
                &-header{
                    padding: 35px 24px;

                    p{
                        &:first-child{
                            width: 55%;
                        }

                        &:last-child{
                            width: 45%;
                            text-align: right;
                        }
                    }
                }

                &-item{
                    li{
                        a{
                            padding: 16px 24px;
                        }

                        p{
                            &:first-child{
                                width: 55%;
                                padding-right: 0;
                            }

                            &:last-child{
                                width: 45%;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }

        .content{
            &-header{
                padding: 48px 0 64px;
                margin-left: 21px;
                margin-right: 21px;

                &-title{
                    margin-bottom: 8px;
                }

                &-desc{
                    text-align: center;

                    svg{
                        display: none;
                    }
                }
            }
        }

        .modal{
            &-header{
                padding: 24px 32px;
            }

            &-body{
                .filter{
                    &-title{
                        margin-bottom: 24px;
                    }

                    &-group{
                        margin-bottom: 32px;

                        &:last-child{
                            margin-top: auto;
                        }
                    }

                    .custom-control{
                        min-height: 0;
                        margin-bottom: 16px;

                        &-input{
                            &:checked{
                                ~ .custom-control-label{
                                    color: $colorSystemWhite;

                                    &::before{
                                        background-color: $colorPrimaryBlack;
                                        border-color: $colorSystemWhite;
                                    }

                                    &::after{
                                        background-image: none;
                                        background-color: $colorSystemWhite;
                                    }
                                }
                            }

                            &:focus ~ .custom-control-label::before{
                                box-shadow: none;
                            }
                        }

                        &-label{
                            &::before{
                                background-color: $colorPrimaryBlack;
                                border: 1px solid $colorSystemWhite;
                            }
                        }
                    }
                }
            }
        }
    }
}
