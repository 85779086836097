.form{
    &-row{
        margin: 0;

        .form-group{
            padding: 0;
            margin-bottom: 22px;

            &:first-child{
                padding-right: 11px;
            }

            &:last-child{
                padding-left: 11px;
            }
        }
    }

    &-group{
        margin-bottom: 10px;

        label{
            margin-bottom: 8px;
        }
    }

    &-control{
        height: auto;
        color: $colorPrimaryBlack;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        padding: 13px 22px;
        background-color: $colorSystemBlack10;
        background-clip: border-box;
        border: 1px solid $colorSystemBlack10;
        border-radius: 0;
        transition: border-color 0.15s ease-in-out;

        &:hover{
            border-color: $colorSystemBlack50;
        }

        &:focus{
            color: $colorPrimaryBlack;
            background-color: $colorSystemBlack10;
            border-color: $colorPrimaryBlack;
            outline: 0;
            box-shadow: none;
        }
    }

    &-header{
        margin-bottom: 64px;

        h2{
            margin-bottom: 24px;
        }
    }

    textarea{
        &.form-control{
            margin-bottom: 22px;
        }
    }

    .customselect{
        select{
            font-family: $fontPrimary;
            font-weight: normal;
            width: 100%;
            padding: 10px 20px;
            border-radius: 0;
            -moz-appearance:none; /* Firefox */
            -webkit-appearance:none; /* Safari and Chrome */
            appearance:none;
        }

        .dropdown{
            display: block;
            position: absolute;
            top: 34px;
            left: 11px;
            width: 95.5%;
            height: 46px;

            &.show{
                .btn{
                    svg{
                        transform: rotate(180deg);
                    }
                }
            }

            &-menu{
                width: 100%;
                background: $colorSystemLight;
                box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
                padding: 0;
                margin: 0;
                border: 0;
                border-radius: 0;
            }

            &-item{
                padding: 13px 22px;

                &:hover{
                    color: $colorPrimaryBlack;
                    background-color: $colorSystemBlack10;
                }
            }

            .btn{
                font-size: 12px;
                width: 100%;
                height: 100%;
                padding: 13px 22px;
                border-radius: 0;

                &::after{
                    display: none;
                }

                &:hover{
                    border-color: $colorSystemBlack50;
                }

                &:focus{
                    border-color: $colorPrimaryBlack;
                    outline: 0;
                    box-shadow: none;
                }

                svg{
                    transition: all .2s ease;
                }
            }
        }
    }

    .custom-control{
        min-height: 0;

        &:hover{
            .custom-control-label{
                &::before{
                    background-color: $colorSystemBlack50;
                    border: 1px solid $colorSystemBlack50;
                }
            }
        }

        &.custom-radio{
            .custom-control-input{
                &:checked{
                    ~ .custom-control-label{
                        &::before{
                            background-color: $colorSystemBlack10;
                            border-color: $colorPrimaryBlack;
                        }

                        &::after{
                            background-image: none;
                            background-color: $colorPrimaryBlack;
                        }
                    }
                }
            }
        }

        &.custom-checkbox{
            .custom-control-input{
                &:checked{
                    ~ .custom-control-label{
                        &::before{
                            background-color: $colorPrimaryBlack;
                        }
                    }
                }

                &:focus ~ .custom-control-label::before{
                    border: 0;
                }
            }
        }

        &-input{
            width: 21px;
            height: 21px;

            &:focus ~ .custom-control-label::before{
                box-shadow: none;
            }
        }

        &-label{
            padding-left: 10px;
            margin-bottom: 0;

            &::before{
                width: 21px;
                height: 21px;
                background-color: $colorSystemBlack10;
                border: 1px solid $colorSystemBlack10;
                top: 0;
            }

            &::after{
                width: 13px;
                height: 13px;
                background-size: 100%;
                border-radius: 10px;
                top: 4px;
                left: -20px;
            }
        }
    }

    .custom-range{
        -webkit-appearance: none;
        width: 100%;
        height: 4px;
        border-radius: 0;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;

        &:hover {
            opacity: 1;
        }

        &:focus{
            &::-webkit-slider-thumb{
                box-shadow: none;
            }
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: $colorPrimaryBlack;
            cursor: pointer;
        }

        &::-moz-range-thumb {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: $colorPrimaryBlack;
            cursor: pointer;
        }
    }

    @include media-breakpoint-down(md){
        &-row{
            .form-group{
                &:first-child{
                    padding-right: 0;
                }

                &:last-child{
                    padding-left: 0;
                }
            }
        }

        &-header{
            margin-bottom: 32px;

            h2{
                margin-bottom: 8px;
            }
        }

        .customselect{
            .dropdown{
                width: 100%;
                left: 0;
                top: 32px;
            }
        }
    }
}
