.progress-site{
    &-gallery{
        .gallery{
            max-width: 842px;
            height: 180px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            transform: translateY(-90px);
            filter: drop-shadow(0px 24px 44px rgba(0, 0, 0, 0.25));

            .item{
                width: 270px;
                height: 180px;
            }

            .event-click-view{
                cursor: pointer;
            }

            &.not-slider{
                display: flex;
                flex-direction: row;
                justify-content: center;

                .gallery-item{
                    margin-right: 16px;

                    &:last-child{
                        margin-right: 0;
                    }
                }

                @include media-breakpoint-down(sm){
                    display: block;

                    .gallery-item{
                        width: 100%;
                        height: 100%;
                        margin-right: 0;

                        img{
                            height: 100%;
                        }
                    }
                }
            }
        }

        .owl-carousel{
            .owl-nav{
                display: block;

                button{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45px;
                    height: 45px;
                    background-color: $colorSystemWhite;
                    box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.25) !important;
                }

                .owl-prev{
                    position: absolute;
                    left: -23px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .owl-next{
                    position: absolute;
                    right: -23px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .no-gallery{
            height: 120px;
        }
    }

    &-disclaimer{
        padding-top: 90px;
        margin-left: 140px;
        margin-right: 90px;

        > h4{
            margin-bottom: 32px;
        }

        .disclaimer-title,
        .disclaimer-desc{
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: -0.01em;

            @include media-breakpoint-down(md){
                font-size: 13px;
                line-height: 22px;
            }
        }

        .disclaimer-title{
            color: $colorPrimaryBlack;
        }

        .disclaimer-desc{
            color: $colorSystemBlack50;
        }
    }

    .table-responsive{
        padding-top: 90px;
        padding-left: 242px;

        .table{
            min-width: 100%;
            background: #fff;
            border-spacing: 0;

            thead{
                tr{
                    th{
                        border-bottom: 0;
                        font-weight: 600;
                        font-size: 12px;
                        color: #8c8c8c;
                        height: 123px;
                        padding: 20px;
                        vertical-align: top;

                        &:first-child{
                            border-top: 0;
                            border-left: 0;
                        }
                    }
                }
            }

            tbody{
                tr{
                    td{
                        min-height: 95px;
                        padding: 17px 21px;
                    }
                }

                &.area{
                    tr{
                        td:first-child{
                            background: #fff;
                        }
                    }
                }
            }

            tbody + tbody{
                border-top: 0;
            }
        }

        .table > thead > tr > th:first-child, .table > tbody > tr > td:first-child {
            position: absolute;
            width: 243px;
            height: 96px;
            display: block;
            background-color: #fff;
            line-height: 60px;
            left: 0;
        }

        .table > thead > tr > th:first-child{
            height: 123px;
        }

        .table > tbody.block > tr > td:first-child{
            font-weight: bold;
            font-size: 18px;
        }

        .table tbody.block tr td:first-child, .table tr td:nth-child(odd),  .table tr th:nth-child(odd){
            background: #F9F9F9;
        }

        .table > tbody > tr > td:not(:first-child){
            padding-right: 0;
        }

        .progresscol{
            border-left: 4px solid #8c8c8c;
            border-right: 4px solid #fff;
            padding: 8px;
            background: linear-gradient(135deg, white 25%,
                rgba(0,0,0,0.03) 25%, rgba(0,0,0,0.03) 50%,
                white 50%, white 75%,
                rgba(0,0,0,0.03) 75%);
            background-size:25px 25px;
            box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
            font-weight: 600;
            color: #151515;
            font-size: 12px;
            height: 60px;
            display: flex;
            align-items: flex-end;

            &.complete{
                border-left: 4px solid #6E67BF;
                border-right: 4px solid #47418E;
                background: #373270;
                color: #fff;
            }
        }

        .clickable{
            .arrow{
                position: absolute;
                right: 17px;
                display: none;
                top: 17px;
            }

            > .rowopen {
                display: block;
                top: 18%;
            }

            &:hover{
                cursor: pointer;
            }

            &.collapsed{
                > .rowopen {
                    display: none;
                }

                > .rowclose{
                    display: block;
                }
            }
        }

        @media only screen and (max-width: 576px){
            padding-left: calc(40% - 1px);

            .table > thead > tr > th{
                height: auto;
            }
            .table > thead > tr > th:first-child, .table > tbody > tr > td:first-child{
                width: 40%;
            }
        }
    }

    .single{
        &-entry{
            &-post{
                padding-top: 90px;
                padding-bottom: 135px;
            }

            &-right{
                .single-entry-desc{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    max-width: 100%;
                }
            }

            &-title{
                margin-bottom: 27px;
            }

            &-desc{
                svg{
                    margin-left: 30px;
                    margin-right: 16px;
                }

                a{
                    text-decoration: underline;

                    &:hover{
                        color: $colorPrimaryBlack;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md){
        &-gallery{
            .container-fluid{
                padding-left: 0;
                padding-right: 0;
            }

            .gallery{
                background-color: $colorSystemBlack70;
                height: auto;
                padding: 32px 0;
                transform: translateY(0);
            }

            .owl-carousel{
                .owl-item{
                    height: 130px;
                    margin-top: 15px;

                    &.active{
                        height: 160px;
                        margin-top: 0;
                    }

                    .item{
                        width: 100%;
                        height: 100%;

                        img{
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }

                .owl-nav{
                    display: none;
                }

                .owl-dots{
                    text-align: center;
                    padding: 24px 0 0;
                }

                .owl-dot{
                    margin-right: 8px;

                    &:last-child{
                        margin-right: 0;
                    }

                    &.active{
                        span{
                            width: 8px;
                            height: 8px;
                            background-color: #FFFFFF;
                            border-radius: 4px;
                        }
                    }

                    span{
                        display: block;
                        width: 6px;
                        height: 6px;
                        background-color: #8C8C8C;
                        border-radius: 3px;
                    }
                }
            }

            .no-gallery{
                display: none;
            }
        }

        &-disclaimer{
            padding-top: 48px;
            margin-left: 47.5px;
            margin-right: 47.5px;

            .disclaimer-title,
            .disclaimer-desc,
            h4{
                text-align: center;
            }

            .disclaimer-title{
                margin-bottom: 16px;
            }
        }

        .single{
            &-entry{
                &-post{
                    padding-top: 48px;
                    padding-bottom: 48px;
                }

                &-right{
                    margin: 0 32.5px;
                }

                &-title{
                    margin-bottom: 16px;
                }

                &-desc{
                    text-align: center;

                    svg{
                        display: none;
                    }
                }
            }
        }

        #modal_full_ehat{
            img{
                top: calc(100% / 5);
                padding: 0 !important;
            }
        }
    }
}
