.hero{
    padding-top: 90px;

    .container-fluid{
        padding-left: 0;
        padding-right: 0;
    }

    .black-space{
        max-width: 90px;
        max-height: 630px;
    }

    &-wrap{
        position: relative;
        width: 100%;
        height: 810px;
        overflow: hidden;

        &::before{
            display: block;
            content: "";
            width: 100%;
            padding-top: (3 / 5) * 100%;
        }

        > .hero-bg{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            img{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            .bgdrop{
                display: block;
                width: 100%;
                height: 368px;
                position: absolute;
                bottom: 0;
                left: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                opacity: 0.75;
            }
        }

        &.video{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: auto;

            &::before{
                display: none;
            }

            .hero-copy{
                position: relative;
                left: 0;
                bottom: 0;
                width: 100%;
                height: auto;
                padding: 30px 90px;
            }

            .scrolldownicon{
                bottom: 28%;
            }
        }
    }

    &-copy{
        position: absolute;
        bottom: 165px;
        left: 90px;
        width: 50vw;

        .title{
            margin-top: auto;
            margin-bottom: 0px;
        }
    }

    @include media-breakpoint-down(md){
        padding-top: 70px;

        .black-space{
            display: none;
        }

        &-wrap{
            height: 530px;

            &::before{
                padding-top: (106 / 75) * 100%;
            }

            &.video{
                .hero-copy{
                    bottom: 0;
                    padding: 30px 0 110px;
                }

                .scrolldownicon{
                    bottom: 10px;
                }
            }
        }

        &-copy{
            width: 100%;
            height: auto;
            left: 0;
            bottom: 120px;
            align-items: center !important;

            .title{
                margin-bottom: 0;
                text-align: center;
                padding: 0 24px;
            }
        }
    }
}
