.contact-us{
    .hero-wrap{
        z-index: 10;
    }

    .single-entry-post{
        position: relative;
        z-index: 1;
    }

    .contact-form{
        padding: 68px 68px 42px;
        background-color: $colorSystemWhite;
        box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.08);

        .cta{
            margin-top: 64px;
            position: relative;
            z-index: 1;
        }

        .button{
            margin-right: 32px;
        }

        .customselect{
            .dropdown{
                width: calc(100% - 11px);
            }
        }
    }

    .join-us{
        padding-top: 20vh;
        padding-bottom: 20vh;

        h1,
        p{
            text-align: center;
        }

        h1{
            width: 100%;
            margin-bottom: 45px;
        }

        p{
            max-width: 610px;
            margin-left: auto;
            margin-right: auto;
        }

        a{
            &:hover{
                color: $colorPrimaryBlack;
            }
        }
    }

    .bg-drop{
        display: block;
        width: 100%;
        height: 678px;
        background-color: $colorSystemLight;
        position: absolute;
        top: -180px;
        left: 0;
        z-index: -1;
    }

    #ThankYouModal{
        .modal-dialog{
            margin-left: auto;
            margin-right: auto;
            max-width: 720px !important;
        }

        .modal-content{
            height: auto;
            padding: 64px 99px;
        }

        .modal-header{
            max-width: 357px;
            align-items: center;
            padding: 0;
            margin: 0 auto 32px;
        }

        .modal-body{
            max-width: 522px;
            margin: 0 auto;
            padding: 0;
        }

        .modal-footer{
            justify-content: center;
            margin-top: 48px;
            padding: 0;
            border: 0;

            .button{
                border: 1px solid $colorSystemWhite;
            }
        }
    }

    .three-column{
        .col{
            &:first-child{
                margin-right: 30px;
            }
            &:last-child{
                padding-right: 0px;
            }
        }
    }

    @include media-breakpoint-down(md){
        .hero-wrap{
            .hero-bg{
                img{
                    object-position: left;
                }
            }
        }

        .contact-form{
            padding: 32px 24px;

            .button{
                margin-right: 0;
                margin-bottom: 16px;
            }

            .customselect{
                .dropdown{
                    width: 100%;
                }
            }
        }

        .join-us{
            padding-top: 15vh;
            padding-bottom: 15vh;

            h1{
                width: 100%;
                margin-bottom: 32px;
                padding: 0 20px;
            }

            p{
                width: 100%;
                padding: 0 20px;
            }
        }

        .bg-drop{
            display: none;
        }

        .three-column{
            padding: 0 20px;

            .col{
                flex-basis: 1;

                &:nth-child(1){
                    padding: 0;
                }
                &:last-child{
                    padding: 0px;
                    padding-right: 0px;
                }
            }

            .row{
                flex-direction: column;
            }

            &-box{
                padding: 9px 10px 16px;
                margin-bottom: 16px;

                p{
                    margin-bottom: 0;
                }
            }
        }

        .location{
            height: 100vh;

            &-open{
                width: 220px;
                height: 60px;
                right: 0;
                left: 0;
                margin-left: auto;
                margin-right: auto;
            }
        }

        #ThankYouModal{
            .modal-dialog{
                margin-left: auto;
                margin-right: auto;
                max-width: 100% !important;
            }

            .modal-content{
                height: auto;
                padding: 48px 30px;
            }

            .modal-header{
                max-width: 100%;
            }

            .modal-body{
                max-width: 100%;
            }

            .modal-footer{
                .button{
                    width: auto !important;
                }
            }
        }
    }
}
